bcl.c.hotelMapCard = {
  props: {
    dynamicClass: ".c-hotel-map-card.dynamicLoading",
    nameLatAttr: "data-location-lat",
    nameLngAttr: "data-location-lng",
    preview: ".map-preview-JS",
    cssSelector: "js-hotel-map-card-swiper",
    cssSelectorSwiper: ".swiper-wrapper[data-items-load]",
    nameAttr: "data-items-load",
    mapContainer: [],
    config: {
      slidesPerView: 1,
      spaceBetween: 16,
      loopedSlides: 1,
      loop: false,
      grabCursor: true,
      wrapperClass: "swiper-wrapper",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
  },

  preInit: function () {
    const $$components = Array.from(document.querySelectorAll(".hotel-map-card"));
    $$components.forEach(($component, index) => {
      bcl.c.hotelMapCard.initEvent($component, index);
    });
  },

  initEvent: function ($component, index) {
    bcl.c.hotelMapCard.props.mapContainer[index] = bcl.map ? $component.querySelector(bcl.map.getSelectorAttr("hotel-map-card")) : null;

    if (!bcl.c.hotelMapCard.props.mapContainer[index]) {
      return;
    }

    bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-hotelMapCard.css", bcl.c.hotelMapCard.props.dynamicClass);

    const $componentMap = $component.querySelector(".c-hotel-map-card");
    if ($componentMap) {
      const $openModal = Array.from($componentMap.querySelectorAll(".js-c-hotel-map-card-open-modal"));
      const $modal = $componentMap.querySelector(".js-c-hotel-map-card__modal");
      const $closeModal = Array.from($componentMap.querySelectorAll(".js-c-hotel-map-card__modal-close"));
      if ($openModal.length > 0 && $modal && $closeModal.length > 0) {
        bcl.c.hotelMapCard.modalHandler($openModal, $modal, $closeModal);
        const $$accordions = Array.from($modal.querySelectorAll(".js-c-hotel-map-card__modal__wrapper__accordion"));
        if ($$accordions.length > 0) {
          bcl.c.hotelMapCard.handleAccordions($$accordions);
        }

        bcl.u.swiper.init($componentMap, bcl.c.hotelMapCard.props);

        const $readMoreText = $modal.querySelector(".js-c-hotel-map-card-read-more-label");
        const $readMoreButton = $modal.querySelector(".js-c-hotel-map-card-read-more-button");
        if ($readMoreText && $readMoreButton) {
          $readMoreButton.addEventListener("click", () => {
            $readMoreText.classList.toggle("--open");
          });
        }

        const $expandButton = $modal.querySelector(".js-c-hotel-map-card__modal-expand");
        const $mapWrapper = $modal.querySelector(".js-c-hotel-map-card__modal__map-wrapper");
        const $contentWrapper = $modal.querySelector(".js-c-hotel-map-card__modal__wrapper-outter");
        if ($expandButton && $mapWrapper && $contentWrapper) {
          $expandButton.addEventListener("click", () => {
            $mapWrapper.classList.toggle("--expanded");
            $contentWrapper.classList.toggle("--expanded");
          });
        }
      }

      const $$preview = Array.from($componentMap.querySelectorAll(bcl.c.hotelMapCard.props.preview));
      $$preview.forEach((preview) => {
        bcl.c.hotelMapCard.generateImagePreview(preview);
      });
      const $previewClick = $componentMap.querySelector(".js-preview-click");
      if ($previewClick) {
        $previewClick.addEventListener("click", (e) => {
          bcl.c.hotelMapCard.triggerInitMap(e, index);
        });
      } else {
        $openModal.forEach((button) => {
          let inited = false;
          button.addEventListener("click", (e) => {
            if (!inited) {
              bcl.c.hotelMapCard.triggerInitMap(e, index);
              inited = true;
            }
          });
        });
      }
    }
  },

  init: function (index) {
    if (!bcl.c.hotelMapCard.props.mapContainer[index]) {
      bcl.u.removeBhgLoading(bcl.c.hotelMapCard.props.mapContainer[index].parentNode);
      return;
    }

    const lat = bcl.c.hotelMapCard.props.mapContainer[index].getAttribute(bcl.c.hotelMapCard.props.nameLatAttr),
      lng = bcl.c.hotelMapCard.props.mapContainer[index].getAttribute(bcl.c.hotelMapCard.props.nameLngAttr);

    let zoom = parseInt(bcl.c.hotelMapCard.props.mapContainer[index].dataset.zoom);

    if (!lat || !lng) {
      bcl.u.removeBhgLoading(bcl.c.hotelMapCard.props.mapContainer[index].parentNode);
      return;
    }

    if (!zoom) {
      zoom = 12;
    }

    const point = new window.google.maps.LatLng(lat, lng);
    const centerMap = point;

    const map = new window.google.maps.Map(bcl.c.hotelMapCard.props.mapContainer[index], {
      center: centerMap, // -0.1
      scrollwheel: false,
      zoom: zoom,
      fullscreenControl: false,
      minZoom: false,
      maxZoom: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_BOTTOM,
      },
      styles: bcl.map.props.mapStyles.defaultStyle,
    });

    new window.google.maps.Marker({
      map: map,
      position: point,
      icon: new window.google.maps.MarkerImage("/content/dam/barcelo/commons/icons/statics/poi_barcelo__black-B.svg", null, null, null, new window.google.maps.Size(48, 48)), // TODO: Add real icon
    });
    bcl.u.removeBhgLoading(bcl.c.hotelMapCard.props.mapContainer[index].parentNode);
  },

  triggerInitMap: async function (e, index) {
    bcl.u.addBhgLoading(bcl.c.hotelMapCard.props.mapContainer[index].parentNode, true);
    bcl.map.init();
    await bcl.c.hotelMapCard.until(() => bcl.map.props.loaded == true);
    bcl.c.hotelMapCard.init(index);
    if (e.target) {
      bcl.u.removeClass(bcl.c.hotelMapCard.props.mapContainer[index], "hidden");
      e.target.parentNode.removeChild(e.target);
    }
  },

  until: function (conditionFunction) {
    const poll = (resolve) => {
      if (conditionFunction()) {
        resolve();
      } else {
        setTimeout(() => poll(resolve), 400);
      }
    };

    return new Promise(poll);
  },

  generateImagePreview: function ($preview) {
    bcl.c.hotelMapCard.setImagePreview($preview);
    window.addEventListener("resize", function () {
      bcl.c.hotelMapCard.setImagePreview($preview);
    });
  },

  setImagePreview: function ($preview) {
    if (!$preview) {
      return;
    }
    if (bcl.u.mobile.isMobile()) {
      $preview.setAttribute("style", "background-image:url(" + $preview.dataset.imgmobile + ");");
    } else {
      $preview.setAttribute("style", "background-image:url(" + $preview.dataset.img + ");");
    }
  },

  modalHandler: function ($openModal, $modal, $closeModal) {
    let pagePosition = 0;
    $openModal.forEach((button) => {
      button.addEventListener("click", () => {
        pagePosition = window.scrollY;
        $modal.classList.add("--open");
        document.body.style.top = "-" + pagePosition + "px";
        document.body.classList.add("tingle-enabled");
        document.querySelector(".js-preview-click")?.click();
      });
    });

    $modal.addEventListener("click", (e) => {
      if (e.target.isEqualNode($modal)) {
        $modal.classList.remove("--open");
        document.body.classList.remove("tingle-enabled");
        window.scrollTo(0, pagePosition);
        document.body.style.top = null;
      }
    });

    $closeModal.forEach((button) => {
      button.addEventListener("click", () => {
        $modal.classList.remove("--open");
        document.body.classList.remove("tingle-enabled");
        window.scrollTo(0, pagePosition);
        document.body.style.top = null;
      });
    });
  },

  handleAccordions: function ($$accordions) {
    $$accordions.forEach((accordion) => {
      const header = accordion.querySelector(".js-c-hotel-map-card__modal__wrapper__accordion__header");
      header.addEventListener("click", () => {
        accordion.classList.toggle("--open");
      });
    });
  },
};

bcl.preInit.push(bcl.c.hotelMapCard.preInit);
