import { cssSelector as carouselSelector } from "barcelo-components/teasers/carousel/js/c-carousel";

bcl.u.breakMobile = 1024;
bcl.u.breakMobilePlus = 1025;

bcl.c.gallery = {
  props: {
    dynamicClass: ".c-gallery.dynamicLoading",
    cssSelector: "c-gallery__item",
    common: "md:p-2",
    config: {
      onecol: {
        cssClass: ["w-full"],
        renditions: ['{"land":' + bcl.u.breakMobile + ', "land":0}'],
      },
      twocol: {
        cssClass: ["w-full md:w-1/2", "w-full md:w-1/2"],
        renditions: ['{"land":' + bcl.u.breakMobile + ', "land":0}', '{"land":' + bcl.u.breakMobile + ', "land":0}'],
      },
      twocol21: {
        cssClass: ["w-full md:w-2/3", "w-full md:w-1/3"],
        renditions: ['{"land":' + bcl.u.breakMobile + ', "land":0}', '{"land":' + bcl.u.breakMobile + ', "square":0}'],
      },
      twocol12: {
        cssClass: ["w-full md:w-1/3", "w-full md:w-2/3"],
        renditions: ['{"land":' + bcl.u.breakMobile + ', "square":0}', '{"land":' + bcl.u.breakMobile + ', "land":0}'],
      },
      threecol: {
        cssClass: ["w-full md:w-1/3", "w-full md:w-1/3", "w-full md:w-1/3"],
        renditions: ['{"land":' + bcl.u.breakMobile + ', "square":0}', '{"land":' + bcl.u.breakMobile + ', "square":0}', '{"land":' + bcl.u.breakMobile + ', "square":0}'],
      },
    },
  },

  loadStyles: function () {
    bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-gallery.css", bcl.c.gallery.props.dynamicClass);
  },

  callback: function (response, $el) {
    bcl.c.gallery.loadStyles();

    const type1 = $el.getAttribute("data-gallery-template1"),
      type2 = $el.getAttribute("data-gallery-template2");
    let elements;

    if (type1 && type2) {
      elements = $el.getElementsByClassName(bcl.c.gallery.props.cssSelector);

      bcl.c.gallery.processRows(elements, bcl.c.gallery.props.config[type1], bcl.c.gallery.props.config[type2]);
    }

    if (!bcl.editMode && (!elements || !elements.length)) {
      const $gallery = $el.closest(".gallery");
      bcl.u.addClass($gallery, "hidden");

      const $divAnchor = $gallery.getElementsByClassName("gallery-anchor-JS")[0],
        $a = document.querySelector('a[href="#' + $divAnchor.dataset.anchor + '"]');

      if ($a) {
        const $parent = $a.closest(".c-landmenu__textbutton");
        $parent && bcl.u.addClass($parent, "hidden");
      }
    }

    $el.removeAttribute("data-gallery-template1");
    $el.removeAttribute("data-gallery-template2");

    bcl.u.triggerEvent($el, "cmp-image-global-redraw");
  },

  processRows: function ($elements, type1, type2) {
    bcl.c.gallery.loadStyles();

    let totalCount = 1;
    let currentRowCount = 1;
    const numItems1 = type1.cssClass.length;
    const numItems2 = type2.cssClass.length;

    for (let i = 0; i < $elements.length; i++) {
      const $item = $elements[i];
      let currentRowType = type1;

      if (totalCount > numItems1) {
        currentRowType = type2;
      }

      bcl.c.gallery.processRow($item, currentRowType, currentRowCount);

      totalCount++;
      if (totalCount > numItems1 + numItems2) {
        totalCount = 1;
      }

      currentRowCount++;
      if ((currentRowType === type1 && currentRowCount > numItems1) || (currentRowType === type2 && currentRowCount > numItems2)) {
        currentRowCount = 1;
      }
    }
  },

  processRow: function ($item, currentRowType, currentRowCount) {
    bcl.c.gallery.loadStyles();

    bcl.u.addClass($item, currentRowType.cssClass[currentRowCount - 1] + " " + bcl.c.gallery.props.common);
    $item.setAttribute("data-rendition-breakpoints", currentRowType.renditions[currentRowCount - 1]);
  },

  beforeInject: function ($el, content) {
    if (!$el.dataset.index) {
      return;
    }

    bcl.c.gallery.loadStyles();

    const index = $el.dataset.index;
    const $content = bcl.u.htmlToDOMElement(content);
    const swiperInsideContent = $content.getElementsByClassName(carouselSelector);

    bcl.u.forEach(swiperInsideContent, function ($swiper) {
      $swiper.setAttribute("data-index", index);
    });

    return $content;
  },
};
